
import templateCSS from "./main.css";
import templateHTML from "./main.html"

class CdmAcceptation {
    data = {};
    // config
    defaultTexts = {
        title: {
            'fr': 'Cookies et vie privée',
            'en': 'Cookies and privacy',
            'es': 'Cookies y privacidad',
        },
        message: {
            'fr': 'En visitant notre site, vous acceptez l\'utilisation des témoins (cookies).<br/>Ces derniers nous permettent de mieux comprendre la provenance de notre clientèle et son utilisation de notre site, en plus d\'en améliorer les fonctions. ',
            'en': 'By using our website, you agree to the use of cookies. <br/>These cookies help us understand how customers arrive at and use our site and help us make improvements.',
            'es': 'Al utilizar nuestro sitio web, acepta el uso de cookies. <br/>Estas cookies nos ayudan a entender cómo los clientes llegan y utilizan nuestro sitio y nos ayudan a realizar mejoras.',
        },
        acceptButtonText: {
            'fr': 'Accepter',
            'en': 'Accept',
            'es': 'Aceptar',
        },
        policyLinkText: {
            'fr': 'Politique de confidentialitée 🡥',
            'en': 'Privacy policy 🡥',
            'es': 'Política de privacidad 🡥'
        },
    }
    hooks = {
        templateReplace: null,
        cssVarBuilder: null
    }
    css = '';
    cssDOMElement = null;
    html = '';
    htmlDOMElement = null;

    /** 
     * Initialise et configure le plugin.
     * @param {object} config 
     * @returns {CdmAcceptation}
     */
    init(config) {
        this.config(config).buildCSS().build().show();
        return this;
    }

    log(variable) {
        if (this.debug) {
            console.info('CdmAcceptation_log: ' + variable)
        }
    }

    /**
     * Ajoute la configuration du popup
     * @param {object} config 
     * @returns {CdmAcceptation}
     */
    config(config) {
        // base
        this.lang = config.lang ?? 'fr';
        this.templateHTML = config.templateHTML ?? templateHTML;
        this.templateCSS = config.templateCSS ?? templateCSS;
        this.domain = window.location.hostname.split('.')[0];
        //CONTENT
        this.title = config.title ?? this.defaultTexts.title[this.lang];
        this.message = config.message ?? this.defaultTexts.message[this.lang];

        this.acceptButtonText = config.acceptButtonText ?? this.defaultTexts.acceptButtonText[this.lang];
        this.policyLinkText = config.policyLinkText ?? this.defaultTexts.policyLinkText[this.lang];
        this.policyLink = config.policyLink ?? '';
        // STYLE
        this.fontFamily = config.fontFamily ?? 'Arial';
        this.fontColor = config.fontColor ?? '#000';
        this.primaryColor = config.primaryColor ?? '#202b38';
        this.maxWidth = config.maxWidth ?? '600px';
        this.alignment = config.alignment ?? 'center';
        this.boxShadow = config.boxShadow ?? '0px 2px 10px #0001';
        this.borderRadius = config.borderRadius ?? '10px';
        this.contentPadding = config.contentPadding ?? '30px 30px';
        this.contentFontSize = config.contentFontSize ?? '14px';
        this.titleFontSize = config.titleFontSize ?? '19px';
        // THEME
        this.themeWide = config.themeWide ?? false;
        // DIMMER
        this.isDimmed = config.isDimmed ?? true;
        this.dimmerColor = config.dimmerColor ?? '#000a';
        this.dimmerClickThrough = config.dimmerClickThrough ?? false;
        // MODAL
        this.modalBgColor = config.modalBgColor ?? config.bgColor ?? '#fff';
        this.modalBorderSpacing = config.modalBorderSpacing ?? { bottom: '30px', left: '30px', right: '30px' }
        this.modalBorderSpacingMobile = config.modalBorderSpacingMobile ?? { bottom: '30px', left: '15px', right: '15px' }
        // HOOKS
        this.hooks.templateReplace = (key, val) => val;
        this.hooks.cssVarBuilder = (val) => val;

        this.debug = config.debug ?? false;

        this.data = this.getData();
        if (this.data.show === undefined) {
            this.setData('show', true);
        }
        this.log('config')
        return this;
    }

    /**
     * Construit le CSS du popup
     * @returns {CdmAcceptation}
     */
    buildCSS() {
        const cssVars = this.hooks.cssVarBuilder({
            '--cdm-dim-color': this.dimmerColor,
            '--cdm-dim-pointerEvents': this.dimmerClickThrough ? 'none' : 'all',
            '--cdm-bg-color': this.modalBgColor,
            '--cdm-border-spacing-bottom': this.modalBorderSpacing.bottom,
            '--cdm-border-spacing-left': this.modalBorderSpacing.left,
            '--cdm-border-spacing-right': this.modalBorderSpacing.right,
            '--cdm-border-spacing-bottom-mobile': this.modalBorderSpacingMobile.bottom,
            '--cdm-border-spacing-left-mobile': this.modalBorderSpacingMobile.left,
            '--cdm-border-spacing-right-mobile': this.modalBorderSpacingMobile.right,
            '--cdm-font-family': this.fontFamily,
            '--cdm-font-color': this.fontColor,
            '--cdm-max-width': this.maxWidth,
            '--cdm-alignment': this.alignment,
            '--cdm-font-primary-color': this.fontPrimaryColor,
            '--cdm-primary-color': this.primaryColor,
            '--cdm-box-shadow': this.boxShadow,
            '--cdm-border-radius': this.borderRadius,
            '--cdm-content-padding': this.contentPadding,
            '--cdm-content-font-size': this.contentFontSize,
            '--cdm-title-font-size': this.titleFontSize,
        })
        this.css = `<style id="cdm-acceptance-css">:root{${Object.keys(cssVars).map(key => `${key}:${cssVars[key]};`).join('')}}${this.templateCSS}</style>`;
        this.log('buildCSS');
        return this;
    }

    /**
     * Construit le html du popup
     * @returns {CdmAcceptation}
     */
    build() {
        const replaces = {
            'root_class': [(this.isDimmed ? 'dimmed' : ''), (this.themeWide ? 'wide' : '')].join(' '),
            'title': this.title,
            'message': this.message,
            'privacy_policy_link_url': this.policyLink,
            'privacy_policy_link_title': this.policyLinkText,
            'accept_btn_text': this.acceptButtonText
        }
        this.html = Object.keys(replaces).reduce((template, key) => {
            const value = replaces[key] ?? '';
            return template.replace(`{{${key}}}`, value, this.hooks.templateReplace(key, value));
        }, this.templateHTML);
        this.log('build');

        return this;
    }

    /**
     * 
     * @param {string} key 
     * @param {any} val 
     */
    setData(key, val) {
        this.data[key] = val;
        document.cookie = 'cdm_pol_policy_data=' + JSON.stringify(this.data) + ';expires=Fri, 31 Dec 9999 23:59:59 GMT;path=/;domain=' + window.location.hostname;
        this.log('setData (' + key + ',' + val + ')');
        return this;
    }

    getData() {
        this.log('getData');
        var match = document.cookie.match(new RegExp('(^| )' + 'cdm_pol_policy_data' + '=([^;]+)'));
        if (match) {
            return JSON.parse(match[2]);
        } else {
            return {};
        }
    }



    /**
     * Affiche le popup
     * @returns {CdmAcceptation}
     */
    show() {

        if (this.data.show) {
            this.log('show')
            // Si le DOM n'est pas pret, attendre
            if (document.readyState !== 'loading') { } else {
                document.addEventListener('DOMContentLoaded', this.show.bind(this));
                return;
            }
            // inserer le CSS
            if (this.cssDOMElement) { this.htmlDOMElement.remove(); }
            document.querySelector('head').insertAdjacentHTML('beforeend', this.css);
            this.cssDOMElement = document.getElementById('cdm-acceptance-css');
            // insérer le html
            if (this.htmlDOMElement) { this.htmlDOMElement.remove(); }
            document.querySelector('body').insertAdjacentHTML('beforeend', this.html);
            this.htmlDOMElement = document.getElementById('cdm-acceptance-modal');

            this.htmlDOMElement.querySelector('#cdm-acceptance-modal-accept').addEventListener('click', this.hide.bind(this), true);
        } else {
            this.log('no-show')
        }
        return this;
    }

    /**
     * 
     * @param {Event} e 
     */
    hide(e) {
        if (this.htmlDOMElement) { this.htmlDOMElement.remove(); }
        if (this.cssDOMElement) { this.htmlDOMElement.remove(); }
        this.setData('show', false);
        this.log('hide')
        return this;
    }
}

window.CdmAcceptation = new CdmAcceptation();